  <template>
    <div class="tw-flex tw-justify-center">
      <div class="page-sculptor">
        <div class="tw-flex mb-5">
          <v-btn class="add-pd" @click="$router.push({name:'ProductDashboard'})">
            <icons name="back" class="mr-2" />
            All Users</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="darker-text" style="letter-spacing:0" text>
            <icons name="update-paddler" class="mr-2" />
            Update User
          </v-btn>
        </div>
        <div class="white-bg">
          <v-col sm="6" cols="12">
            <div class="image-border">
              <img class="existing-img" :src="selectedUser.productUserLogo" />
            </div>
          </v-col>
          <v-col sm="6" cols="12" class="tw-flex tw-justify-center tw-flex-col">
            <p class="add-pd"> {{selectedUser.productUserName || "N/A"}} </p>
            <span class="darker-text tw-flex my-4">
              {{selectedUser.subscription}}</span>
            <v-chip v-if="selectedUser.verified === true" color="#00C48C69">
              <span class="td-style" style="color: #0F674E">
                Active
              </span>
            </v-chip>
            <v-chip v-if="selectedUser.verified === false" color="#C8486759">
              <span class="td-style" style="color: #71011C">
                Inactive
              </span>
            </v-chip>
          </v-col>
        </div>
        <div class="my-4"></div>
        <div class="white-bg">
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Category
            </span>
            <span class="darker-text mt-3 text-uppercase">
              {{selectedUser.category || "N/A"}}
            </span>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              HQ
            </span>
            <span class="darker-text mt-3" v-if="selectedUser.address">
              {{selectedUser.address.state || "N/A"}}
            </span>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Verification
            </span>
            <div class="tw-w-1/12">
            <v-switch  inset hide-details v-model="selectedUser.verified" color="#004AAD"></v-switch>
            </div>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Terminals
            </span>
            <span class="darker-text mt-3">
              {{selectedUser.totalTerminalsOrOperationalJetties || "N/A"}}
            </span>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Customer
            </span>
            <span class="darker-text mt-3">
              {{selectedUser.totalCustomers || "N/A"}}
            </span>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Bookings
            </span>
            <span class="darker-text mt-3">
              {{selectedUser.totalBookings || "N/A"}}
            </span>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Profit
            </span>
            <span class="darker-text mt-3">
              {{selectedUser.totalRevenue / 100 || "N/A"}}
            </span>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              POS Devices
            </span>
            <span class="darker-text mt-3">
              {{selectedUser.totalPosDevices || "N/A"}}
            </span>
          </v-col>
          <v-col sm="4" cols="6" class="tw-flex tw-flex-col mb-4">
            <span class="lighter-text">
              Start Date
            </span>
            <span class="darker-text mt-3">
              {{selectedUser.startDate || "N/A"}}
            </span>
          </v-col>
        </div>
        <div class="my-4"></div>
        <div class="white-bg tw-flex-col">
          <p class="lighter-text mb-3">Documents</p>
          <div class="tw-flex tw-flex-wrap">
            <v-col sm="5" cols="12" v-if="selectedUser.cacCertificate" class="design">
              <a :href="selectedUser.cacCertificate">
                <v-icon size="15px" class="mr-2" color="#004ADE">mdi-file-document-outline</v-icon>
                <span>CAC certificate
                </span>
              </a>
            </v-col>
            <div v-else class="tw-flex tw-justify-center">
            <span class="lighter-text void">Nothing to see here</span>
          </div>
          </div>

        </div>
      </div>
    </div>
  </template>

  <script>
    import {
      mapGetters
    } from 'vuex';
    import Icons from '../../components/reuseables/Icons.vue';
    export default {
      components: {
        Icons
      },
      name: "ViewUser",
      data() {
        return {
          isChecked: false,
        };
      },
      props: {},
      methods: {
        toggle() {
          if (this.selectedUser.verified) {
            this.isChecked = true
          } else {
            this.isChecked = false
          }
        },
      },
      computed: {
        ...mapGetters('products', ['selectedUser'])
      },
      created() {
        this.toggle()
      }
    }
  </script>

  <style lang="scss" scoped>
    .add-pd {
      color: #263238;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: none;
      letter-spacing: 0;
      margin-bottom: 15px;

      @media (max-width:768px) {
        justify-content: start;
        font-size: 17px;
      }
    }

    .lighter-text,
    .darker-text {
      color: #939393;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      text-transform: capitalize !important;
      line-height: 20px;
      word-wrap: break-word;

      @media (max-width:576px) {
        font-size: 12px;
      }
    }

    .darker-text {
      color: #263238;
    }

    .white-bg {
      background-color: #fff;
      padding: 30px;
      height: fit-content;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;

      @media (max-width:768px) {
        padding: 15px;
      }
    }

    .existing-img {
      width: auto;
      object-fit: cover;
      height: 200px;
      @media screen and (max-width:768px) {}
    }

    .page-sculptor {
      display: flex;
      flex-direction: column;
      width: 650px;

      @media (max-width:768px) {
        width: 100%;
      }
    }

    .each-dc {
      border-radius: 10px;
      border: 2px solid rgba(189, 189, 189, 0.12);
      background: #FEFFFF;
      height: 40px;
      margin: 10px;

    }

    .v-chip.v-size--default {
      width: 80px;
      height: 27px;
      display: flex;
      justify-content: center;
      font-size: 12px !important;
      text-transform: lowercase;
    }

    .text-headings {
      color: #4F4F4F;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .image-border img {
      border-radius: 20px;
    }

    .design {
      border-radius: 10px;
      border: 2px solid rgba(189, 189, 189, 0.12);
      background: #FEFFFF;
      margin: 5px;
      height: fit-content;
    }

    .design a {
      color: rgba(38, 50, 56, 0.66);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .void {
      text-align: center;
      font-size: 11px;
      color: #e6e3e3;
    }

    .toggle-button {
      display: inline-block;
      position: relative;
      width: 40px;
      height: 20px;
    }

    .toggle-checkbox {
      display: none;
    }

    .toggle-label {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(238, 236, 236, 1);
      border-radius: 34px;
      transition: background-color 0.3s;
    }

    .toggle-checkbox:checked+.toggle-label {
      background-color: #004aad;
    }

    .toggle-label:before {
      position: absolute;
      content: '';
      height: 14px;
      width: 14px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      border-radius: 50%;
      transition: transform 0.3s;
    }

    .toggle-checkbox:checked+.toggle-label:before {
      transform: translateX(20px);
    }
  </style>